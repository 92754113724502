import React from 'react';
import { Link } from 'gatsby';

import Layout from '../layout';
import SEO from '../components/seo';

import Container from '../components/container';

const TermsPage = () => {
  return (
    <Layout>
      <SEO title="Terms of Services" description={``} />

      <Container>
        <section className="mt-12 prose max-w-none">
          <h1>Terms of Services</h1>

          <h2>Introduction</h2>
          <p>
            Welcome to Nolan Oak ("Company", "we", "our", "us")! These Terms of Service ("Terms",
            "Terms of Service") govern your use of our web pages located at{' '}
            <Link to="/">https://nolanoak.com</Link> by Nolan Oak. Our Privacy Policy also governs
            your use of our Service and explains how we collect, safeguard, and disclose information
            that results from your use of our web pages. Please read it here{' '}
            <Link to="/privacy">https://nolanoak.com/privacy</Link>. Your agreement with us includes
            these Terms and our Privacy Policy ("Agreements"). You acknowledge that you have read
            and understood Agreements, and agree to be bound of them. If you do not agree with (or
            cannot comply with) Agreements, then you may not use the Service, but please let us know
            by emailing at info@nolanoak.com so we can try to find a solution. These Terms apply to
            all visitors, users and others who wish to access or use Service. Thank you for being
            responsible.
          </p>

          <h2>Communications</h2>
          <p>
            By creating an Account on our Service, you agree to subscribe to newsletters, marketing
            or promotional materials and other information we may send. However, you may opt out of
            receiving any, or all, of these communications from us by following the unsubscribe link
            or by emailing at info@nolanoak.com.
          </p>

          <h2>Purchases</h2>
          <p>
            If you wish to purchase any product or service made available through Service
            ("Purchase"), you may be asked to supply certain information relevant to your Purchase
            including, without limitation, your credit card number, the expiration date of your
            credit card, your billing address, and your shipping information. You represent and
            warrant that: (i) you have the legal right to use any credit card(s) or other payment
            method(s) in connection with any Purchase; and that (ii) the information you supply to
            us is true, correct and complete. We may employ the use of third party services for the
            purpose of facilitating payment and the completion of Purchases. By submitting your
            information, you grant us the right to provide the information to these third
            parties subject to our Privacy Policy. We reserve the right to refuse or cancel your
            order at any time for reasons including but not limited to: product or service
            availability, errors in the description or price of the product or service, error in
            your order, or other reasons. We reserve the right to refuse or cancel your order if
            fraud or an unauthorized or illegal transaction is suspected.
          </p>

          <h2>Subscriptions</h2>
          <p>
            Some parts of Service are billed on a subscription basis ("Subscription(s)"). You will
            be billed in advance on a recurring and periodic basis ("Billing Cycle"). Billing cycles
            are set on a monthly basis. At the end of each Billing Cycle, your Subscription will
            automatically renew under the exact same conditions unless you cancel it or Nolan
            Oak cancels it. You may cancel your Subscription renewal by contacting Nolan Oak
            customer support team. A valid payment method, including credit card, is required to
            process the payment for your subscription. You shall provide Nolan Oak with accurate and
            complete billing information including full name, address, province, postal code,
            telephone number, and a valid payment method information. By submitting such payment
            information, you automatically authorize Nolan Oak to charge all Subscription fees
            incurred through your account to any such payment instruments. Should automatic billing
            fail to occur for any reason, Nolan Oak will issue an electronic invoice indicating that
            you must proceed manually, within a certain deadline date, with the full payment
            corresponding to the billing period as indicated on the invoice.
          </p>

          <h2>Content</h2>
          <p>
            Content found on or through this Service are the property of Nolan Oak or used with
            permission. You may not distribute, modify, transmit, reuse, download, repost, copy, or
            use said Content, whether in whole or in part, for commercial purposes or for personal
            gain, without express advance written permission from us.
          </p>

          <h2>Prohibited Uses</h2>
          <p>
            You may use Service only for lawful purposes and in accordance with Terms. You agree not
            to use Service:
          </p>
          <ol>
            <li>
              In any way that violates any applicable national or international law or regulation.
            </li>
            <li>
              For the purpose of exploiting, harming, or attempting to exploit or harm minors in any
              way by exposing them to inappropriate content or otherwise.
            </li>
            <li>
              To transmit, or procure the sending of, any advertising or promotional material,
              including any "junk mail", "chain letter," "spam," or any other similar solicitation.
            </li>
            <li>
              To impersonate or attempt to impersonate Company, a Company employee, another user, or
              any other person or entity.
            </li>
            <li>
              In any way that infringes upon the rights of others, or in any way is illegal,
              threatening, fraudulent, or harmful, or in connection with any unlawful, illegal,
              fraudulent, or harmful purpose or activity.
            </li>
            <li>
              To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of
              Service, or which, as determined by us, may harm or offend Company or users of Service
              or expose them to liability.
            </li>
          </ol>

          <p>Additionally, you agree not to:</p>
          <ol>
            <li>
              Use Service in any manner that could disable, overburden, damage, or impair Service or
              interfere with any other party’s use of Service, including their ability to engage in
              real time activities through Service.
            </li>
            <li>
              Use any robot, spider, or other automatic device, process, or means to access Service
              for any purpose, including monitoring or copying any of the material on Service.
            </li>
            <li>
              Use any manual process to monitor or copy any of the material on Service or for any
              other unauthorized purpose without our prior written consent.
            </li>
            <li>
              Use any device, software, or routine that interferes with the proper working of
              Service.
            </li>
            <li>
              Introduce any viruses, trojan horses, worms, logic bombs, or other material which is
              malicious or technologically harmful.
            </li>
            <li>
              Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts
              of Service, the server on which Service is stored, or any server, computer, or
              database connected to Service.
            </li>
            <li>
              Attack Service via a denial-of-service attack or a distributed denial-of-service
              attack.
            </li>
            <li>Take any action that may damage or falsify Company rating.</li>
            <li>Otherwise attempt to interfere with the proper working of Service.</li>
          </ol>

          <h2>Analytics</h2>
          <p>
            We may use third-party Service Providers to monitor and analyze the use of our Service.
          </p>

          <h3>Google Analytics</h3>
          <p>
            Google Analytics is a web analytics service offered by Google that tracks and reports
            website traffic. Google uses the data collected to track and monitor the use of our
            Service. This data is shared with other Google services. Google may use the collected
            data to contextualise and personalise the ads of its own advertising network. For more
            information on the privacy practices of Google, please visit the Google Privacy Terms
            web page:{' '}
            <a href="https://policies.google.com/privacy?hl=en" target="_blank" rel="noreferrer">
              https://policies.google.com/privacy?hl=en
            </a>{' '}
            We also encourage you to review the Google's policy for safeguarding your data:{' '}
            <a
              href="https://support.google.com/analytics/answer/6004245"
              target="_blank"
              rel="noreferrer"
            >
              https://support.google.com/analytics/answer/6004245
            </a>
            .
          </p>

          <h2>No Use By Minors</h2>
          <p>
            Service is intended only for access and use by individuals at least eighteen (18) years
            old. By accessing or using any of Company, you warrant and represent that you are at
            least eighteen (18) years of age and with the full authority, right, and capacity to
            enter into this agreement and abide by all of the terms and conditions of Terms. If you
            are not at least eighteen (18) years old, you are prohibited from both the access and
            usage of Service.
          </p>

          <h2>Accounts</h2>
          <p>
            When you create an account with us, you guarantee that you are above the age of 18, and
            that the information you provide us is accurate, complete, and current at all times.
            Inaccurate, incomplete, or obsolete information may result in the immediate termination
            of your account on Service. You are responsible for maintaining the confidentiality of
            your account and password, including but not limited to the restriction of access to
            your computer and/or account. You agree to accept responsibility for any and all
            activities or actions that occur under your account and/or password, whether your
            password is with our Service or a third-party service. You must notify us immediately
            upon becoming aware of any breach of security or unauthorized use of your account. You
            may not use as a username the name of another person or entity or that is not lawfully
            available for use, a name or trademark that is subject to any rights of another person
            or entity other than you, without appropriate authorization. You may not use as a
            username any name that is offensive, vulgar or obscene. We reserve the right to refuse
            service, terminate accounts, remove or edit content, or cancel orders in our sole
            discretion.
          </p>

          <h2>Intellectual Property</h2>
          <p>
            Service and its original content (excluding Content provided by users), features and
            functionality are and will remain the exclusive property of Nolan Oak and its licensors.
            Service is protected by copyright, trademark, and other laws of Canada. Our trademarks
            and trade dress may not be used in connection with any product or service without the
            prior written consent of Nolan Oak.
          </p>

          <h2>Error Reporting and Feedback</h2>
          <p>
            You may provide us directly at info@nolanoak.com with information and feedback
            concerning errors, suggestions for improvements, ideas, problems, complaints, and other
            matters related to our Service ("Feedback"). You acknowledge and agree that: (i) you
            shall not retain, acquire or assert any intellectual property right or other right,
            title or interest in or to the Feedback; (ii) Company may have development ideas similar
            to the Feedback; (iii) Feedback does not contain confidential information or proprietary
            information from you or any third party; and (iv) Company is not under any obligation of
            confidentiality with respect to the Feedback. In the event the transfer of the ownership
            to the Feedback is not possible due to applicable mandatory laws, you grant Company and
            its affiliates an exclusive, transferable, irrevocable, free-of-charge, sub-licensable,
            unlimited and perpetual right to use (including copy, modify, create derivative works,
            publish, distribute and commercialize) Feedback in any manner and for any purpose.
          </p>

          <h2>Links To Other Web Sites</h2>
          <p>
            Our service may contain links to third party web sites or services that are not owned or
            controlled by Nolan Oak. Nolan Oak has no control over, and assumes no responsibility
            for the content, privacy policies, or practices of any third party web sites or
            services. We do not warrant the offerings of any of these entities/individuals or their
            websites. You acknowledge and agree that Nolan Oak shall not be responsible or liable,
            directly or indirectly, for any damage or loss caused or alleged to be caused by or in
            connection with use of or reliance on any such content, goods or services available on
            or through any such third party web sites or services. We strongly advise you to read
            the terms of service and privacy policies of any third party web sites or services that
            you visit.
          </p>

          <h2>Disclaimer Of Warranty</h2>
          <p>
            These services are provided by company on an "as is" and "as available" basis. Company
            makes no representations or warranties of any kind, express or implied, as to the
            operation of their services, or the information, content or materials included therein.
            You expressly agree that your use of these services, their content, and any services or
            items obtained from us is at your sole risk. Neither company nor any person associated
            with company makes any warranty or representation with respect to the completeness,
            security, reliability, quality, accuracy, or availability of the services. Without
            limiting the foregoing, neither company nor anyone associated with company represents or
            warrants that the services, their content, or any services or items obtained through the
            services will be accurate, reliable, error-free, or uninterrupted, that defects will be
            corrected, that the services or the server that makes it available are free of viruses
            or other harmful components or that the services or any services or items obtained
            through the services will otherwise meet your needs or expectations. Company hereby
            disclaims all warranties of any kind, whether express or implied, statutory, or
            otherwise, including but not limited to any warranties of merchantability,
            non-infringement, and fitness for particular purpose. The foregoing does not affect any
            warranties which cannot be excluded or limited under applicable law.
          </p>

          <h2>Limitation Of Liability</h2>
          <p>
            Except as prohibited by law, you will hold us and our officers, directors, employees,
            and agents harmless for any indirect, punitive, special, incidental, or consequential
            damage, however it arises (including attorneys' fees and all related costs and expenses
            of litigation and arbitration, or at trial or on appeal, if any, whether or not
            litigation or arbitration is instituted), whether in an action of contract, negligence,
            or other tortious action, or arising out of or in connection with this agreement,
            including without limitation any claim for personal injury or property damage, arising
            from this agreement and any violation by you of any federal, provincial, or local laws,
            statutes, rules, or regulations, even if company has been previously advised of the
            possibility of such damage. Except as prohibited by law, if there is liability found on
            the part of company, it will be limited to the amount paid for the products and/or
            services, and under no circumstances will there be consequential or punitive damages.
            Some provinces do not allow the exclusion or limitation of punitive, incidental or
            consequential damages, so the prior limitation or exclusion may not apply to you.
          </p>

          <h2>Termination</h2>
          <p>
            We may terminate or suspend your account and bar access to Service immediately, without
            prior notice or liability, under our sole discretion, for any reason whatsoever and
            without limitation, including but not limited to a breach of Terms. If you wish to
            terminate your account, you may simply discontinue using Service. All provisions of
            Terms which by their nature should survive termination shall survive termination,
            including, without limitation, ownership provisions, warranty disclaimers, indemnity and
            limitations of liability.
          </p>

          <h2>Governing Law</h2>
          <p>
            These Terms shall be governed and construed in accordance with the laws
            of Ontario without regard to its conflict of law provisions. Our failure to enforce any
            right or provision of these Terms will not be considered a waiver of those rights. If
            any provision of these Terms is held to be invalid or unenforceable by a court, the
            remaining provisions of these Terms will remain in effect. These Terms constitute the
            entire agreement between us regarding our Service and supersede and replace any prior
            agreements we might have had between us regarding Service.
          </p>

          <h2>Changes To Service</h2>
          <p>
            We reserve the right to withdraw or amend our Service, and any service or material we
            provide via Service, in our sole discretion without notice. We will not be liable if for
            any reason all or any part of Service is unavailable at any time or for any period. From
            time to time, we may restrict access to some parts of Service, or the entire Service, to
            users, including registered users.
          </p>

          <h2>Amendments To Terms</h2>
          <p>
            We may amend Terms at any time by posting the amended terms on this site. It is your
            responsibility to review these Terms periodically. Your continued use of the Platform
            following the posting of revised Terms means that you accept and agree to the changes.
            You are expected to check this page frequently so you are aware of any changes, as they
            are binding on you. By continuing to access or use our Service after any revisions
            become effective, you agree to be bound by the revised terms. If you do not agree to the
            new terms, you are no longer authorized to use Service.
          </p>

          <h2>Waiver And Severability</h2>
          <p>
            No waiver by Company of any term or condition set forth in Terms shall be deemed a
            further or continuing waiver of such term or condition or a waiver of any other term or
            condition, and any failure of Company to assert a right or provision under Terms shall
            not constitute a waiver of such right or provision. If any provision of Terms is held by
            a court or other tribunal of competent jurisdiction to be invalid, illegal or
            unenforceable for any reason, such provision shall be eliminated or limited to the
            minimum extent such that the remaining provisions of Terms will continue in full force
            and effect.
          </p>

          <h2>Acknowledgement</h2>
          <p>
            By using service or other services provided by us, you acknowledge that you have read
            these terms of service and agree to be bound by them.
          </p>

          <h2>Contact Us</h2>
          <p>
            Please send your feedback, comments, requests for technical support: By email:
            info@nolanoak.com
          </p>

          <p>Last updated: August 25, 2020</p>
        </section>
      </Container>
    </Layout>
  );
};

export default TermsPage;
